import { createBrowserRouter } from 'react-router-dom';
import Privacy from './components/Privacy/index';
import PrivacyPolicy from './components/PrivacyPolicy/index';
import HtmlRenderer from './components/htmlRenderer';
import Landing from './components/landingpage';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <div>
        <Landing />
      </div>
    ),
  },
  {
    path: '/privacy-policy',
    element: (
      <div>
        <PrivacyPolicy />
      </div>
    ),
  },
  {
    path: '/delete-instructions',
    element: <Privacy />,
  },
]);
export default router;
