import React, { Component } from 'react';
import '../../styles/privacyStyles.css';
export default class extends Component {
  render() {
    return (
      <body className="innerPage landing-page bgimg-main">
        <div className="header-section innerPage full-width">
          <div className="wrapper flex-center">
            <div className="logo full-width">
              <a href="index.html">
                {' '}
                <h1 style={{ margin: '0' }}>Food Court</h1>{' '}
              </a>
            </div>
          </div>
        </div>
        <div className="banner-section innerPage full-width">
          <div className="wrapper">
            <h1>Privacy Policy</h1>
            <h5>
              Access to the Mobile Application and use of the Services offered on the Mobile
              Application by SPECTRUM is subject to this Privacy Policy. By accessing the Mobile
              Application and by continuing to use the Services presented, you are deemed to have
              accepted this Privacy Policy, and, you are deemed to have consented to our use and
              disclosure of your personal information in the manner prescribed in this Privacy
              Policy. We reserve the right to amend this Privacy Policy from time to time. If you
              disagree with any part of this Privacy Policy, you must immediately discontinue your
              access to the Mobile Application and your use of the Services.
            </h5>

            <p>
              As part of the normal operation of our Services, we collect, use and, in some cases,
              disclose information about you to third parties. Accordingly, we have developed this
              Privacy Policy in order for you to understand how we collect, use, communicate and
              disclose and make use of your personal information when you use the Services on the
              Mobile Application:
            </p>
            <p>
              (a) Before or at the time of collecting personal information, we will identify the
              purposes for which information is being collected.
            </p>
            <p>
              This includes: User profile: We collect data when users create or update their
              FoodCourt Driver App accounts. This may include their name, email, phone/mobile
              number, login name and password and profile picture.
            </p>
            <p>
              This includes; Location data: We collect precise or approximate location data from a
              user’s mobile device if enabled by the user to do so. For drivers and delivery
              partners, FoodCourt Driver App collects this data when the FoodCourt Driver App is
              running in the foreground (app open and on-screen) or background (app open but not
              on-screen) of their mobile device.
            </p>
            <p>
              (b) We will collect and use of personal information solely with the objective of
              fulfilling those purposes specified by us and for other compatible purposes, unless we
              obtain the consent of the individual concerned or as required by law.
            </p>
            <p>
              (c) We will only retain personal information as long as necessary for the fulfillment
              of those purposes.
            </p>
            <p>
              (d) We will collect personal information by lawful and fair means and, where
              appropriate, with the knowledge or consent of the individual concerned.
            </p>
            <p>
              (e) Personal information should be relevant to the purposes for which it is to be
              used, and, to the extent necessary for those purposes, should be accurate, complete,
              and up-to-date.
            </p>
            <p>
              (f) We will protect personal information by reasonable security safeguards against
              loss or theft, as well as unauthorized access, disclosure, copying, use or
              modification.
            </p>
            <p>
              We are committed to conducting our business in accordance with these principles in
              order to ensure that the confidentiality of personal information is protected and
              maintained.
            </p>
          </div>
        </div>

        <div className="footer-section innerPage full-width">
          <div className="wrapper">
            <div
              style={{
                textAlign: 'center',
                float: 'left',
                width: '100%',
              }}
            >
              Any questions or comments regarding FoodCourt Terms of use should be directed to us by
              e-mail at
              <a href="mailto:info@foodcourtapps.com">info@foodcourtapps.com.</a>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
